<template>
	<div class="user-panel">
		<div class="header">
			<span>基本信息</span>
		</div>

		<div class="content">
			<div class="single-item">
				<span class="item-label">用户名</span>
				<span class="item-content" :title="userInfo.nickName">{{userInfo.nickName}}</span>
			</div>

			<div class="single-item">
				<span class="item-label">用户UID</span>
				<span class="item-content" :title="userInfo.userName">
					{{ userInfo.userName }}
				</span>
				<el-button class="copy-icon" type="text" @click="copyUid" icon="el-icon-copy-document"></el-button>
			</div>

			<div class="single-item">
				<span class="item-label" :title="userInfo.partnerName">所属合作方</span>
				<span class="item-content">{{userInfo.partnerName}}</span>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import $$ from "axios"
	import _ from "lodash"
	export default{
		name : "user-panel",
		data(){
			return {
				userInfo : {}
			}
		},
		methods : {
			getUserInfo(){
				let url = `/api-intl-translate/user/operation-anonymous/current-user-info`;
				$$.get(url).then(data => {
					this.userInfo = _.cloneDeep(data);
				}).catch(err => {
					console.log(err.message);
				})
			},
			copyUid() {
				this.$copyText(this.userInfo.userName);
				this.$message.success("用户UID已复制到剪切板");
			},
		},
		mounted(){
			this.getUserInfo();
		}
	};
</script>

<style lang="scss">
	.user-panel{
		background-color : #fff;
		width : 250px;
		padding : 20px;

		.content{
			margin-top : 10px;
			.single-item {
				line-height : 32px;
				height : 32px;
				font-size : 14px;
				display : flex;

				.item-label {
					display : inline-block;
					width : 75px;
					text-align : right;
					flex-shrink: 0;
				}
				.item-content {
					display : inline-block;
					margin-left : 20px;
					max-width : 160px;
					overflow : hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.copy-icon {
					padding: 0;
					margin-left: 3px;
				}
			}
		}
	}
</style>