<template>
	<el-menu
		class="v-meu-new"
		mode = "horizontal"
		background-color="#3f51b5"
		text-color="#fff"
		active-text-color="#ffd04b"
		:default-active="$route.path"
		:router="true">
			<menu-item :menus="menus"></menu-item>
	</el-menu>
</template>

<script type="text/javascript">
	import MenuItem from "./MenuItem.vue"
	export default {
		name : "v-menu-list",
		components : {
			MenuItem
		},
		props : {
			//传进来的菜单数据
			menus : {
				type : Array,
				default : ()=>([])
			}
		}
	};
</script>

<style lang="scss">
	.v-meu-new{
		&.el-menu--popup-bottom-start{
			margin-top : -5px !important;
		}
		&.el-menu--horizontal > .el-submenu .el-submenu__title , &.el-menu--horizontal > .el-menu-item{
			height : 48px !important;
			line-height : 48px !important;
		}
		&.el-menu.el-menu--horizontal{
			border-bottom : 0px !important;
		}
	}
	
</style>