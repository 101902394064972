<template>
	<fragment>
		<template v-for="(menu , index) in menus">
			<el-submenu :index="menu.name" v-if="menu.childs && menu.childs.length > 0" :key="index">
				<template slot="title">
					<span class="menu-content">{{$te(menu.lang_key) ? $t(menu.lang_key) : menu.desc}}</span>
				</template>
				<v-menu-item :menus="menu.childs"></v-menu-item>
			</el-submenu>
			<el-menu-item v-else :index="menu.router" :key="index">
				<span class="menu-content">{{$te(menu.lang_key) ? $t(menu.lang_key) : menu.desc}}</span>
			</el-menu-item>
		</template>
	</fragment>
</template>

<script type="text/javascript">
	export default{
		name : "v-menu-item",
		props : {
			menus : {
				type : [Object , Array],
				default : ()=>({})
			}
		}
	};
</script>

<style lang="scss" scoped="">
	.menu-content{
		min-width : 75px;
		display : inline-block;
	}
</style>